<template>
  <div clas="flex-block">
    <b-card
      no-body
      style="position: sticky; top: 100px; z-index: 99"
      class="ml-3 pb-1"
    >
      <b-row align-h="end" >
        <b-col sm="4" lg="3">
          <b-card class="mb-0 text-center"> TRIMESTRAL </b-card>
          <div class="d-flex justify-content-around">
            <div class="mb-0"> Unidades </div>
            <div class="mb-0"> Tiempo/UD </div>
            <div class="mb-0"> Tiempo </div>
          </div>
        </b-col>
        <b-col sm="4" lg="3">
          <b-card class="mb-0 text-center"> SEMESTRAL </b-card>
          <div class="d-flex justify-content-around">
            <div class="mb-0"> Unidades </div>
            <div class="mb-0"> Tiempo/UD </div>
            <div class="mb-0"> Tiempo </div>
          </div>
        </b-col>
        <b-col sm="4" lg="3">
          <b-card class="mb-0 text-center"> ANUAL </b-card>
          <div class="d-flex justify-content-around">
            <div class="mb-0"> Unidades </div>
            <div class="mb-0"> Tiempo/UD </div>
            <div class="mb-0"> Tiempo </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="ml-3">
      <b-row align-h="end">
        <b-col sm="4" lg="3">
          <b-card
            header-tag="header"
            class="mb-0"
            border-variant="light"
            header-class="px-1 py-1"
          >
            <template #header>
              <span class="font-weight-bolder">1 ud. Rev TRIMESTRAL</span>
              <span class="font-weight-bolder">
                {{ calculationData.totals ? calculationData.totals.trimestral.total : 0 | numberToLocalString }}
              </span>
            </template>
            <div class="d-flex justify-content-between">
              <span>Dias 1 técnico</span>
              <span>{{ calculationData.totals ? calculationData.totals.trimestral.days_1_tec : 0 | numberToLocalString }}</span>
            </div>
          </b-card>
          <b-card
            header-tag="header"
            class="mb-0 mt-2"
            border-variant="light"
            header-class="px-1 py-1"
          >
            <template #header>
              <span class="font-weight-bolder">2 ud. Rev TRIMESTRAL</span>
              <span class="font-weight-bolder">
                {{ calculationData.totals ? calculationData.totals.trimestral_2.total : 0 | numberToLocalString}}
              </span>
            </template>
            <div class="d-flex justify-content-between">
              <span>Dias 1 técnico</span>
              <span>{{ calculationData.totals ? calculationData.totals.trimestral_2.days_1_tec : 0 | numberToLocalString}}</span>
            </div>
          </b-card>
        </b-col>
        <b-col sm="4" lg="3">
          <b-card
            header-tag="header"
            class="mb-0"
            border-variant="light"
            header-class="px-1 py-1"
          >
            <template #header>
              <span class="font-weight-bolder">2 ud. Rev SEMESTRAL</span>
              <span class="font-weight-bolder">
                {{ calculationData.totals ? calculationData.totals.semestral.total : 0 | numberToLocalString}}
              </span>
            </template>
            <div class="d-flex justify-content-between">
              <span>Dias 1 técnico</span>
              <span>{{ calculationData.totals ? calculationData.totals.semestral.days_1_tec : 0 | numberToLocalString}}</span>
            </div>
          </b-card>
        </b-col>
        <b-col sm="4" lg="3">
          <b-card
            header-tag="header"
            class="mb-0"
            border-variant="light"
            header-class="px-1 py-1"
          >
            <template #header>
              <span class="font-weight-bolder">2 ud. Rev ANUAL</span>
              <span class="font-weight-bolder">
                {{ calculationData.totals ? calculationData.totals.anual.total : 0 | numberToLocalString }}
              </span>
            </template>
            <div class="d-flex justify-content-between">
              <span>Dias 1 técnico</span>
              <span>{{ calculationData.totals ? calculationData.totals.anual.days_1_tec : 0 | numberToLocalString }}</span>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col v-for="data in calculationData.rateLines" cols="12">
        <b-card>
          <span>{{ data.provision_category.name }}</span>
        </b-card>
        <b-card v-for="rate_line in data.rate_lines" class="ml-3">
          <b-row align-v="center">
            <b-col sm="12" lg="3">
              <span>{{ rate_line.provision.name }}</span>
            </b-col>

            <b-col class="text-center">
              <span>{{ rate_line.rate_lines.Trimestral.quantity | numberToLocalString  }}</span>
            </b-col>
            <b-col class="text-center">
              <span>{{ rate_line.rate_lines.Trimestral.time_ud | numberToLocalString }}</span>
            </b-col>
            <b-col class="text-center"> 
              <span>{{ rate_line.rate_lines.Trimestral.time_total | numberToLocalString }}</span>
            </b-col>

            <b-col class="text-center">
              <span>{{ rate_line.rate_lines.Semestral.quantity | numberToLocalString }}</span>
            </b-col>
            <b-col class="text-center">
              <span>{{ rate_line.rate_lines.Semestral.time | numberToLocalString }}</span>
            </b-col>
            <b-col class="text-center"> 
              <span>{{ rate_line.rate_lines.Semestral.time_total | numberToLocalString }}</span>
            </b-col>

            <b-col class="text-center">
              <span>{{ rate_line.rate_lines.Anual.quantity | numberToLocalString }}</span>
            </b-col>
            <b-col class="text-center">
              <span>{{ rate_line.rate_lines.Anual.time | numberToLocalString }}</span>
            </b-col>
            <b-col class="text-center"> 
              <span>{{ rate_line.rate_lines.Anual.time_total | numberToLocalString }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RaterApi from "@/api/rater-api";
import JobContractsApi from '@/api/job-contracts-api'

export default {
  name: "RaterCalculationTable",
  props: {
    jobId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      calculationData: [],
    };
  },
  computed: {
    contractSelected () {
      return this.jobId ? this.$store.getters['job/getContractSelected'](this.jobId) : null
    },
  },
  methods: {
    async loadCalculationData() {
      let offerId

      if(this.jobId) {
        const response = await JobContractsApi.getContract(this.contractSelected.contract.id)
        offerId = response.data.offer.id
      } else {
        offerId = this.$route.params.id;
      }

      this.$emit("updateLoading", true);

      try {
        const response = await RaterApi.getCalculation(offerId);
        if(this.jobId) {
          const totalsRateLines = response.data.rateLines.map((rateLine) => {
            rateLine.total = 0

            rateLine.rate_lines.forEach((rate_line) => {
              ['Anual', 'Semestral', 'Trimestral'].forEach(period => {
                if (rate_line.rate_lines[period].quantity) {
                  rateLine.total += rate_line.rate_lines[period].quantity
                }
                if(rate_line.rate_lines[period].time) {
                  rateLine.total += rate_line.rate_lines[period].time
                }
                if(rate_line.rate_lines[period].time_total) {
                  rateLine.total += rate_line.rate_lines[period].time_total
                }
              })
            })

            return rateLine
          })

          const data = response.data
          data.rateLines = totalsRateLines.filter(({ total }) => total > 0)

          this.calculationData = data
        } else {
          this.calculationData = response.data;
        }
      } finally {
        this.$emit("updateLoading", false);
      }
    },
  },
  mounted() {
    this.loadCalculationData();
  },
};
</script>
