<template>
  <b-overlay variant="transparent" :show="loading" rounded="sm" :blur="'0'">
    <b-tabs lazy pills class="pt-2">
      <b-tab title="Toma de datos" class="pb-1">
        <RaterDataCollectionTable
          v-show="!loading"
          @updateLoading="loading = $event"
          @enabledNextSections="enabledNextSections = $event"
          ref="data-collection-table"
        />
      </b-tab>
      <b-tab title="Tiempos" class="pb-1" :disabled="!enabledNextSections">
        <RaterDataTimeCollectionTable
          v-show="!loading"
          @updateLoading="loading = $event"
        />
      </b-tab>
      <b-tab title="Calculo" class="pb-1" :disabled="!enabledNextSections">
        <RaterCalculationTable
          v-show="!loading"
          @updateLoading="loading = $event"
        />
      </b-tab>
      <b-tab title="Precios" class="pb-1" :disabled="!enabledNextSections">
        <RaterPricesTables
          v-show="!loading"
          @updateLoading="loading = $event"
        />
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import RaterDataCollectionTable from "@/components/rater/table/RaterDataCollectionTable";
import RaterDataTimeCollectionTable from "@/components/rater/table/RaterDataTimeCollectionTable";
import RaterCalculationTable from "@/components/rater/table/RaterCalculationTable";
import RaterPricesTables from "@/components/rater/table/RaterPricesTables";

export default {
  name: "Rater",
  components: {
    RaterDataCollectionTable,
    RaterDataTimeCollectionTable,
    RaterCalculationTable,
    RaterPricesTables,
  },
  data() {
    return {
      enabledNextSections: false,
      loading: false,
    };
  },
};
</script>
