<template>
  <div clas="flex-block">
    <b-card no-body style="position: sticky; top: 100px; z-index: 99;" class="ml-3">
      <b-row align-h="end">
        <b-col sm="6" lg="5">
          <b-card class="mb-0 text-center"> 
            MINUTOS 
          </b-card>
          <div class="d-flex justify-content-around">
            <b-card class="mb-0" body-class="pt-0">
              Trimestral
            </b-card>
            <b-card class="mb-0" body-class="pt-0 pb-1">
              Semestral
            </b-card>
            <b-card class="mb-0" body-class="pt-0">
              Anual
            </b-card>
          </div>
        </b-col>
        <b-col sm="6" lg="5">
          <b-card class="mb-0 text-center">
            HORAS
          </b-card>
          <div class="d-flex justify-content-around">
            <b-card class="mb-0" body-class="pt-0">
              Trimestral
            </b-card>
            <b-card class="mb-0" body-class="pt-0 pb-1">
              Semestral
            </b-card>
            <b-card class="mb-0" body-class="pt-0">
              Anual
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col v-for="data in dataCollection" :key="data.provision_category.id" cols="12">
        <b-card>
          <span>{{ data.provision_category.name }}</span>
        </b-card>
        <b-card v-for="rate_line in data.rate_lines" :key="rate_line.id" class="ml-3">
          <b-row align-v="center">
            <b-col sm="12" lg="2">
              <span>{{ rate_line.provision.name }}</span>
            </b-col>
            <b-col class="text-center">
              <span>{{ rate_line.trimestral_minutes || 0 | numberToLocalString }}</span>
            </b-col>
            <b-col class="text-center">
              <span>{{ rate_line.semestral_minutes || 0 | numberToLocalString }}</span>
            </b-col>
            <b-col class="text-center">
              <span>{{ rate_line.anual_minutes || 0 | numberToLocalString }}</span>
            </b-col>
            <b-col class="text-center">
              <span>{{ rate_line.trimestral_hours || 0 | numberToLocalString }}</span>
            </b-col>
            <b-col class="text-center">
              <span>{{ rate_line.semestral_hours || 0 | numberToLocalString }}</span>
            </b-col>
            <b-col class="text-center">
              <span>{{ rate_line.anual_hours || 0 | numberToLocalString }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RaterApi from "@/api/rater-api";

export default {
  name: "RaterDataTimeCollectionTable",
  data() {
    return {
      dataCollection: [],
      updateData: []
    };
  },
  methods: {
    async loadDataCollection() {
      const offerId = this.$route.params.id;
      this.$emit("updateLoading", true);

      try {
        const response = await RaterApi.getRate(offerId);

        this.dataCollection = response.data.rateLines;
      } finally {
        this.$emit("updateLoading", false);
      }
    },
  },
  mounted() {
    this.loadDataCollection();
  },
};
</script>
