<template>
    <b-row>
      <b-col v-for="data in dataCollection" :key="data.provision_category.id" sm="12" lg="7">
        <b-card>
          <span>{{ data.provision_category.name }}</span>
        </b-card>
        <b-card v-for="rate_line in data.rate_lines" :key="rate_line.id" class="ml-3">
          <b-row align-v="center">
            <b-col>
              <span>{{ rate_line.provision.name }}</span>
            </b-col>
            <b-col>
              <b-form-input
                v-model="rate_line.quantity"
                type="number"
                @input="changeData(rate_line.id, rate_line.quantity)"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col class="d-flex justify-content-end" cols="7">
        <b-btn :disabled="disabledSave" variant="primary" @click="handleUpdate">
          Guardar
        </b-btn>
      </b-col>
    </b-row>
</template>

<script>
import RaterApi from "@/api/rater-api";

export default {
  name: "RaterDataCollectionTable",
  data() {
    return {
      dataCollection: [],
      updateData: []
    };
  },
  computed: {
    validValue() {
      return this.dataCollection.flatMap(({ rate_lines }) => rate_lines).some(({ quantity }) => quantity >= 1) 
    },
    disabledSave() {
      return this.updateData.length === 0
    }
  },
  methods: {
    async loadDataCollection() {
      const offerId = this.$route.params.id;
      this.$emit('updateLoading', true)

      try {
        const response = await RaterApi.getRate(offerId);

        this.dataCollection = response.data.rateLines;
      } finally {
        this.$emit('updateLoading', false)
        this.$emit('enabledNextSections', this.validValue)
      }
    },
    changeData(rateLineId, quantity) {
      const exist = this.updateData.some(({ id }) => id === rateLineId)

      if(exist) {
        this.updateData = this.updateData.map((data) => {
          if(data.id === rateLineId) {
            data.quantity = quantity
          }
          return data
        })
      } else {
        this.updateData.push({ id: rateLineId, quantity })
      }
    },
    async handleUpdate() {
      this.$emit("updateLoading", true);
      try {
       const updatePromises = this.updateData.map(async ({ id, quantity }) => {
         await RaterApi.updateRate(id, { quantity });
       });
     
       await Promise.all(updatePromises);
      } finally {
        this.updateData = []
        this.loadDataCollection();
      }
    },
  },
  mounted() {
    this.loadDataCollection();
  },
};
</script>
