import Vue from 'vue'
import { BaseApi } from '@/api/base-api'
import { RESOURCE_RATES } from '@/shared/constants/resources'

class RaterApi extends BaseApi {
  constructor(resource) {
    super(resource)
    this.resource = resource
  }
  
  getRate (offer_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/get-rate/${offer_id}`)
        resolve(response.data)
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al obtener el recurso.')
        reject(error)
      }
    })
  }

  updateRate (rateid, params) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/rate-line/${rateid}`, params)
        resolve(response.data)
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al actualizar el recurso.')
        reject(error)
      }
    })
  }

  getCalculation(offer_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/get-calcul/${offer_id}`)
        resolve(response.data)
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al obtener el recurso.')
        reject(error)
      }
    })
  }

  getRates (offer_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/${offer_id}`)
        resolve(response.data)
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al obtener el recurso.')
        reject(error)
      }
    })
  }

  updateRates(offer_id, params) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/${offer_id}`, params)
        resolve(response.data)
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al actualizar el recurso.')
        reject(error)
      }
    })
  }

  overturn(offer_id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Vue.prototype.$http.post(`${this.resource}/dump-pricing/${offer_id}`)
        Vue.$toast('Tarifas volcadas con éxito.')
        resolve(response.data)
      } catch (error) {
        Vue.$toast.error('Ocurrió un error al actualizar el recurso.')
        reject(error)
      }
    })
  }
}


export default new RaterApi(RESOURCE_RATES)
